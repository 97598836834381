* {
  /* color: #fff; */
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  /* color: white; */
  /* background: #333; */
}

.text-theme {
  color: #43a39f;
}

.btn.bth-theme {
  background-color: #43a39f;
  border: 2px solid #43a39f;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  text-align: center;
}
.btn.bth-theme-outline {
  border: 1px solid #43a39f;
  background-color: transparent;
  border-radius: 4px;
  color: #43a39f;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  text-align: center;
}

.bth-theme:hover {
  background-color: #378885;
}
